const loadFirstClosedPolls = (services, params) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.polls.setClosedPollsLoading());

        const page = 0;
        const onlyFirst = params.onlyFirst || false;

        const res = await api.polls.getAllClosedPolls({
            session: state.user.session,
            page,
            onlyFirst,
        });

        let closedPolls = [];
        let hasMorePages = false;

        if (res.ok) {
            closedPolls = res.polls;
            hasMorePages = res.hasMore;
        }

        dispatch(actions.polls.setClosedPolls({
            closedPolls,
            hasMorePages,
        }));
    };
};

const preloadFirstClosedPolls = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const polls = state.polls.closedPolls;

        if (polls.length > 1) {
            return;
        }

        dispatch(actions.polls.setClosedPollsLoading());

        const page = 0;
        const onlyFirst = false;

        const res = await api.polls.getAllClosedPolls({
            session: state.user.session,
            page,
            onlyFirst,
        });

        let closedPolls = [];
        let hasMorePages = false;

        if (res.ok) {
            closedPolls = res.polls;
            hasMorePages = res.hasMore;
        }

        dispatch(actions.polls.setClosedPolls({
            closedPolls,
            hasMorePages,
        }));
    };
};

const loadClosedPolls = (services, params) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.polls.setClosedPollsLoading());

        const page = params.page || 0;

        const res = await api.polls.getAllClosedPolls({
            session: state.user.session,
            page,
        });

        let closedPolls = [];
        let hasMorePages = false;

        if (res.ok) {
            closedPolls = res.polls;
            hasMorePages = res.hasMore;
        }

        dispatch(actions.polls.addClosedPolls({
            closedPolls,
            hasMorePages,
        }));
    };
};

const loadMoreClosedPolls = (services) => {
    return (dispatch, getState) => {
        const state = getState();

        const polls = state.polls.closedPolls;
        const page = state.polls.page || 0;

        if (page === 0 && polls.length < 10) {
            dispatch(loadFirstClosedPolls(services, {
                onlyFirst: false,
            }));
            return;
        }

        dispatch(loadClosedPolls(services, {
            page: page + 1,
        }));
    };
};

const submitPollAnswer = (services, params) => {
    const { api, actions, events } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const actionsCommonParams = {
            juiceId: params.juiceId,
            storyId: params.storyId,
            answerId: params.answerId,
        };

        if (params.isPreview) {
            dispatch(actions.juices.setJuicePollAnswered(actionsCommonParams));
            dispatch(actions.juiceStories.setStoryInfoStoryPollAnswer(actionsCommonParams));
            return;
        }

        const session = state.user.session || "";

        events.student.selectPollAnswer({
            ...actionsCommonParams,
            session,
        });

        dispatch(actions.juiceStories.setStoryInfoStoryViewed(actionsCommonParams));
        dispatch(actions.juices.setJuicePollLoading(actionsCommonParams));

        const res = await api.polls.setPollAnswer({
            ...actionsCommonParams,
            session,
            pollId: params.pollId,
        });

        if (res.ok) {
            const poll = res.poll || {};

            dispatch(actions.juices.setJuicePollLoaded({
                ...actionsCommonParams,
                poll,
            }));

            dispatch(actions.juiceStories.setStoryInfoStoryPollAnswer({
                ...actionsCommonParams,
                poll,
            }));
        } else {
            dispatch(actions.juices.setJuicePollError({
                ...actionsCommonParams,
                error: res.error || text.error,
            }));
        }
    };
};

export default {
    loadFirstClosedPolls,
    preloadFirstClosedPolls,
    loadClosedPolls,
    loadMoreClosedPolls,
    submitPollAnswer,
};
