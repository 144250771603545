export default (apiURL, methods) => {
    return {
        getStudentGrades(params = {}) {
            // NOTE: action=get_student_grades
            const url = `${apiURL}/student/grades`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        setStudentGrade(params = {}) {
            // NOTE: action=send_grade_change_to_queue
            const url = `${apiURL}/student/grades`;

            return methods.post(url, {
                session_id: params.session || "",
                grade: params.grade || "",
            });
        },

        getClasses(params = {}) {
            // NOTE: action=get_student_all_classes
            const url = `${apiURL}/student/classes`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        getClassInfo(params = {}) {
            // NOTE: action=student_get_class_details
            const url = `${apiURL}/student/classes/${params.classId}`;

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        joinClassByClassCode(params = {}) {
            // NOTE: action=student_join_class_by_class_code
            const url = `${apiURL}/student/classes/join-by-code`;

            return methods.postForm(url, {
                session_id: params.session,
                code: params.classCode,
            });
        },

        getJuiceStoriesInfoByJuiceId(params = {}) {
            // NOTE: action=daily_juices_published_get_user_info_by_juice_id
            const url = `${apiURL}/student/daily-juices/${params.juiceId}/user-info`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },
    };
};
